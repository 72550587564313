<template>
  <div class="container">
    <div v-if="inventory">
      <v-sheet :elevation="2" class="ma-0 ma-md-2 pa-4 text-center">
        <div>
          <div
            class="d-flex flex-column flex-md-row justify-space-around"
            v-if="editLocation"
          >
            <v-autocomplete
              label="Inventory Location"
              placeholder="Choose a location for inventory"
              v-model="inventory.locationId"
              :items="locations"
              item-text="name"
              item-value="id"
              clearable
              solo
              hide-details
            />
            <div class="mt-4 mt-md-0">
              <v-btn @click="updateLocation" color="primary" class="ml-4">
                {{ $vuetify.lang.t("$vuetify.base.save") }}
              </v-btn>
              <v-btn @click="editLocation = false" class="ml-4">
                {{ $vuetify.lang.t("$vuetify.base.cancel") }}
              </v-btn>
            </div>
          </div>
          <v-btn v-else @click="editLocation = true" text>
            {{
              inventory.locationName ? inventory.locationName : "No Location"
            }}
          </v-btn>

          <v-divider v-if="editLocation" />
        </div>
        <div class="text-subtitle-2 ma-2">
          {{ inventory.productName }} ({{ translateStatus(inventory.status) }})
        </div>
        <div class="d-flex justify-space-between text-caption">
          <span>
            {{ $vuetify.lang.t("$vuetify.label.id") }}: {{ inventory.id }}
          </span>
          <v-divider class="mx-2" vertical />
          <span>
            {{ $vuetify.lang.t("$vuetify.inventory.documentNo") }}:
            {{ inventory.documentNumber }}
          </span>
          <v-divider class="mx-2" vertical />
          <span>{{ inventory.stockTime | formatDateYear }}</span>
        </div>

        <v-divider />

        <div class="text-subtitle-1 ma-2">
          <div class="d-flex justify-space-between">
            <span> {{ $vuetify.lang.t("$vuetify.inventory.weight") }} </span>
            <span style="width: 250px">
              <div class="d-flex justify-space-between">
                <span>
                  {{ $vuetify.lang.t("$vuetify.inventory.original") }}
                </span>
                <span
                  >{{ inventory.quantity | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</span
                >
              </div>
              <div class="d-flex justify-space-between">
                <span> {{ $vuetify.lang.t("$vuetify.inventory.used") }} </span>
                <span>
                  -{{
                    (inventory.quantity - inventory.quantityAvailable)
                      | formatNumber
                  }}
                  {{ productInfo ? productInfo.measureWeight : "" }}
                </span>
              </div>
              <div class="d-flex justify-space-between">
                <span>
                  {{ $vuetify.lang.t("$vuetify.inventory.reserved") }}
                </span>
                <span
                  >-{{ reservedQuantity | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</span
                >
              </div>
              <v-divider />
              <div class="d-flex justify-space-between">
                <span>
                  {{ $vuetify.lang.t("$vuetify.inventory.remains") }}
                </span>
                <span
                  >{{
                    (inventory.quantityAvailable - reservedQuantity)
                      | formatNumber
                  }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</span
                >
              </div>
            </span>
          </div>
          <div class="d-flex justify-space-between mt-4">
            <span>
              {{
                $vuetify.lang.t(
                  "$vuetify.inventory.OriginalUnit",
                  productInfo ? productInfo.measureUnit : ""
                )
              }}
            </span>
            <span>
              <inventory-unit-details
                v-model="inventory"
                :count="inventory.unit"
              />
            </span>
          </div>
          <div
            v-for="field in inventory.fields"
            :key="field.id"
            class="d-flex justify-space-between"
          >
            <span>{{ field.field }}</span>
            <span>
              <span v-if="field.type == 'Number'">{{
                field.fieldValueNumber | formatNumber
              }}</span>
              <span v-else-if="field.type == 'Date'">{{
                field.fieldValueDate | formatDateYear
              }}</span>
              <span v-else>{{ field.fieldValueString }}</span>
            </span>
          </div>

          <div v-if="coa" class="d-flex justify-space-between">
            <span>CoA</span>
            <span>
              <v-icon
                @click="openCoaWindow"
                color="primary"
              >
                mdi-certificate
              </v-icon>
            </span>
          </div>
        </div>
      </v-sheet>

      <div
        v-if="nodes && nodes[0].childrens && nodes[0].childrens.length > 0"
        class="text-center"
        :style="{
          height: $vuetify.breakpoint.mobile ? '600px' : '300px',
          position: 'relative',
        }"
      >
        <flow-chart
          :nodes="nodes"
          :orientation="$vuetify.breakpoint.mobile ? 'vertical' : 'horizontal'"
          @node-click="viewNode"
          style="position: relative; z-index: 1"
        />

        <div
          v-if="selectedWorksheetId"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            background-color: white;
          "
          class="text-center"
        >
          <v-btn @click="selectedWorksheetId = null" text> Close </v-btn>
          <worksheet-card
            class="mb-4"
            :worksheet="selectedWorksheetId"
            :key="selectedWorksheetId"
          />
        </div>
      </div>
    </div>

    <v-skeleton-loader
      v-else
      v-bind="attrs"
      type="list-item-two-line"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const InventoryUnitDetails = () =>
  import("@/components/inventory/InventoryUnitDetails.vue");
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
import { tagReservedInventories } from "@/services/inventoryReservation.js";
import FlowChart from "@/components/charts/FlowChart.vue";

export default {
  props: {
    value: {
      type: [Object, Number],
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      inventory: null,
      productInfo: null,
      reservedQuantity: 0,
      reservedWorksheetIds: [],
      editLocation: false,
      nodes: null,
      selectedWorksheetId: null,
    };
  },
  computed: {
    ...mapGetters("locations", ["locations"]),

    usedByProcessWorksheets() {
      if (this.inventory && this.inventory.inputWorksheetId2Quantity) {
        const worksheetIds = Object.keys(
          this.inventory.inputWorksheetId2Quantity
        );
        if (worksheetIds && worksheetIds.length > 0) {
          return worksheetIds.map((id) => parseInt(id));
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    usedByPackagingWorksheets() {
      if (this.inventory && this.inventory.packagingWorksheetId2Quantity) {
        let worksheetIds = Object.keys(
          this.inventory.packagingWorksheetId2Quantity
        );
        const validWorksheetIds = worksheetIds.filter((id) => id > 0);
        if (validWorksheetIds && validWorksheetIds.length > 0) {
          return validWorksheetIds.map((id) => parseInt(id));
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    coa() {
      if (this.inventory && this.inventory.coa) {
        const finalTests = this.inventory.coa.filter((c) => c.finalTest);
        if (finalTests && finalTests.length > 0) {
          return finalTests[0];
        } else {
          return this.inventory.coa[0];
        }
      } else {
        return null;
      }
    },
  },
  components: {
    WorksheetCard,
    InventoryUnitDetails,
    FlowChart,
  },
  async mounted() {
    this.loadLocations();

    if (this.value && typeof this.value == "object") {
      this.inventory = this.value;
    } else if (this.value && typeof this.value == "number") {
      await this.fetchInventory(this.value);
    } else {
      console.error("No inventory found");
    }

    if (this.inventory) {
      await tagReservedInventories([this.inventory]);
      if (
        this.inventory.reservedByWorksheets &&
        this.inventory.reservedByWorksheets.length > 0
      ) {
        this.reservedQuantity = this.inventory.reservedByWorksheets.reduce(
          (sum, r) => (sum += r.quantity ? r.quantity : 0),
          0
        );
        // get unique processWorksheetId from reservedByWorksheets
        const uniqueWorksheetIds = new Set(
          this.inventory.reservedByWorksheets.map((r) => r.processWorksheetId)
        );
        this.reservedWorksheetIds = [...new Set(uniqueWorksheetIds)];
      }
      this.loadProductInfo(this.inventory.productId);

      this.nodes = this.generateFlowChartNodes(this.inventory);
    } else {
      this.addErrorMessage("Error retrieving inventory details");
    }
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("categoryStore", ["fetchCategory"]),
    ...mapActions("locations", ["loadLocations"]),

    viewNode(data) {
      console.log("node data: ", data);
      if (data.type != "Inventory" && data.id > 0) {
        this.selectedWorksheetId = data.id;
      } else {
        this.selectedWorksheetId = null;
      }
    },
    generateFlowChartNodes(inventory) {
      const nodes = [];

      const inventoryNode = {
        type: "Inventory",
        color: "#77dd77",
        size: 20,
        name: inventory.productName,
        id: inventory.id,
        description: `Quantity: ${inventory.quantity}`,
        childrens: [],
      };

      if (inventory.processOutputWorksheetId) {
        const outputNode = {
          type: "Process Output",
          color: "#aec6cf",
          size: 20,
          name: `Process Ouput`,
          description: `Worksheet ID ${inventory.processOutputWorksheetId}`,
          id: inventory.processOutputWorksheetId,
          childrens: [],
        };
        nodes.push(outputNode);
        outputNode.childrens.push(inventoryNode);
      } else if (inventory.purchaseWorksheetId) {
        const purchaseNode = {
          type: "Purchase",
          color: "#aec6cf",
          size: 20,
          name: `Recieving`,
          description: `Worksheet ID ${inventory.purchaseWorksheetId}`,
          id: inventory.purchaseWorksheetId,
          childrens: [],
        };
        nodes.push(purchaseNode);
        purchaseNode.childrens.push(inventoryNode);
      } else {
        nodes.push(inventoryNode);
      }

      if (inventory.inputWorksheetId2Quantity) {
        for (const worksheetId in inventory.inputWorksheetId2Quantity) {
          const inputNode = {
            type: "Process Input",
            color: "#cfcfc4",
            size: 20,
            name: `Process Input (${worksheetId})`,
            id: parseInt(worksheetId),
            description: `Quantity: ${inventory.inputWorksheetId2Quantity[worksheetId]}`,
            childrens: [],
          };
          inventoryNode.childrens.push(inputNode);
        }
      }

      if (inventory.packagingWorksheetId2Quantity) {
        for (const worksheetId in inventory.packagingWorksheetId2Quantity) {
          const packagingNode = {
            type: "Packaging",
            color: "#cfcfc4",
            size: 20,
            name: `Packaging (${worksheetId})`,
            id: parseInt(worksheetId),
            description: `Quantity: ${inventory.packagingWorksheetId2Quantity[worksheetId]}`,
            childrens: [],
          };
          inventoryNode.childrens.push(packagingNode);
        }
      }

      if (inventory.reservedByWorksheets) {
        for (const reserve of inventory.reservedByWorksheets) {
          const reservedNode = {
            type: "Reserved",
            color: "#cfcfc4",
            size: 20,
            name: `Reserved (${reserve.processWorksheetId})`,
            id: reserve.processWorksheetId,
            description: `Quantity: ${reserve.quantity}`,
            childrens: [],
          };
          inventoryNode.childrens.push(reservedNode);
        }
      }

      return nodes;
    },
    async loadProductInfo(id) {
      if (id) {
        return await this.fetchCategory(id).then((resp) => {
          this.productInfo = resp;

          return this.productInfo;
        });
      } else {
        return null;
      }
    },
    async fetchInventory(inventoryId) {
      return await this.$axios
        .get("/inventory/" + inventoryId)
        .then((response) => {
          this.inventory = response.data;
          return this.inventory;
        })
        .catch(() => {
          this.inventory = null;
          this.addErrorMessage("No inventory found with ID " + inventoryId);
          return null;
        });
    },
    updateLocation() {
      const params = { locationId: this.inventory.locationId };
      this.$axios
        .put(`inventory/relocate/${this.inventory.id}`, null, { params })
        .then(() => {
          const newLocation = this.locations.find(
            (l) => l.id == this.inventory.locationId
          );
          this.inventory.locationName = newLocation ? newLocation.name : null;
          this.addSuccessMessage("Inventory location updated successfully");
          this.editLocation = false;
          this.$emit("updatedLocation", this.inventory);
        });
    },
    translateStatus(status) {
      if (status == "Available") {
        return this.$vuetify.lang.t("$vuetify.inventory.available");
      } else if (status == "Not Available") {
        return this.$vuetify.lang.t("$vuetify.inventory.notAvailable");
      }
    },
    openCoaWindow() {
      const coaUrl = this.$router.resolve({
        name: 'coa',
        params: { id: this.coa.id }
      }).href;
      window.open(coaUrl, '_blank');
    }
  },
};
</script>

<style></style>
